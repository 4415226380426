import AngelswingLogo from '^/assets/icons/logo.svg';
import route from '^/constants/routes';
import { UseL10n, useL10n, useRole } from '^/hooks';
import { useLastDSMOrMapContent } from '^/hooks/useLastContent';
import {
  ChangeContentsSidebarTab,
  ChangeIsInContentsHistoryLogTable,
  OpenContentPagePopup,
  SetUpdateTwoDDisplayCenter,
  SetUpdateTwoDDisplayZoom,
  TogglePrintView,
} from '^/store/duck/Pages';
import { usePopupStore } from '^/store/popup';
import theme from '^/theme';
import * as T from '^/types';
import {
  isAllowAcessEventLogs,
  isAllowDownloadPopup,
  isAllowProjectShare,
  isAllowSharePopup,
} from '^/utilities/role-permission-check';
import React, {
  MouseEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';
import { CANCELLABLE_CLASS_NAME } from '../CreatingVolumeClickEventHandler';
import Text from './text';

const tabItemStyle: CSSObject = {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '44px',
  height: '44px',
  margin: '8px',
  borderRadius: '5px',
};

const LogoContainer = styled.section<{
  isDisabled?: boolean;
  isActive?: boolean;
  customStyles?: CSSObject;
}>(({ isDisabled, isActive, customStyles }) => ({
  ...tabItemStyle,

  width: '100%',
  height: '60px',
  borderRadius: 0,
  margin: 0,
  cursor: isDisabled ? 'default' : 'pointer',

  backgroundColor: isActive ? theme.colors.neutralBlue[500] : undefined,
  '& path': {
    fill: isActive ? theme.colors.white[100] : undefined,
  },

  '&:hover': (() => {
    if (isDisabled) {
      return undefined;
    }

    return {
      backgroundColor: isActive ? undefined : theme.colors.coolGray[200],

      '> svg path': (() => {
        if (isActive) {
          return;
        }
        if (isDisabled) {
          return { fill: theme.colorTokens.disabledGray };
        }

        return { fill: theme.colors.coolGray[500] };
      })(),
    };
  })(),
  ...customStyles,
}));

const CustomLogoImage = styled.img({
  maxWidth: '30px',
});

const DropdownMenu = styled.div({
  position: 'absolute',
  top: '60px',
  left: 0,
  backgroundColor: theme.colors.blackVariants.blackLight,

  padding: '8px 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',

  gap: '',

  zIndex: 1000,
});

const DropdownItem = styled.div({
  padding: '5px 36px',
  width: '152px',
  cursor: 'pointer',
  color: theme.colors.white[100],
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '16px',
  letterSpacing: '0.06px',
  '&:hover': {
    backgroundColor: theme.colors.white[10],
  },
});

const DropdownDivider = styled.div({
  height: '1px',
  width: '100%',
  backgroundColor: theme.colors.white[10],
  margin: '8px 0px',
});

interface Props {
  customStyles?: CSSObject;
}

export function LogoTab({ customStyles }: Props) {
  const printingContentId = useSelector((s: T.State) => s.Pages.Contents.printingContentId);
  const squareLogoUrl = useSelector((s: T.State) => s.PlanConfig.config?.squareLogoUrl);

  const isInPointCloud: boolean = useSelector((s: T.State) => s.Pages.Contents.in3DPointCloud);

  const lastMapId: T.Content['id'] | undefined = useLastDSMOrMapContent(T.ContentType.MAP)?.id;
  const sidebarTab = useSelector((s: T.State) => s.Pages.Contents.sidebarTab);

  const dispatch = useDispatch();
  const role: T.PermissionRole = useRole();

  const [showMenu, setShowMenu] = useState(false);
  const [l10n]: UseL10n = useL10n();

  const navigate = useNavigate();

  const popupStore = usePopupStore();

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleLogoClick = (): void => {
    if (printingContentId) {
      return;
    }
    setShowMenu(!showMenu);
  };

  const logo: ReactNode = squareLogoUrl ? (
    <CustomLogoImage src={squareLogoUrl} />
  ) : (
    <AngelswingLogo />
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mouseup', handleClickOutside);

    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [dropdownRef?.current]);

  const handleDownloadDataClick: MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();
    setShowMenu(false);
    dispatch(
      OpenContentPagePopup({
        popup: isAllowDownloadPopup(role)
          ? T.ContentPagePopupType.DOWNLOAD
          : T.ContentPagePopupType.NO_PERMISSION,
      })
    );
  };

  const handleDataHistoryClick: MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();
    setShowMenu(false);
    if (!isAllowAcessEventLogs(role)) {
      dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.NO_PERMISSION }));

      return;
    }
    dispatch(ChangeIsInContentsHistoryLogTable({ isInContentsEventLogTable: true }));
  };

  const handlePrintClick = () => dispatch(TogglePrintView({ contentId: lastMapId }));

  const handleMeasurementReportClick = useCallback(() => {
    if (isInPointCloud) {
      return;
    }

    if (sidebarTab !== T.ContentPageTabType.MEASUREMENT) {
      dispatch(ChangeContentsSidebarTab({ sidebarTab: T.ContentPageTabType.MEASUREMENT }));
    }

    dispatch(
      OpenContentPagePopup({
        popup: T.ContentPagePopupType.REPORT_DOWNLOAD,
      })
    );
  }, [isInPointCloud]);

  const handleInviteclick: MouseEventHandler<HTMLDivElement> = useCallback(() => {
    if (isAllowProjectShare(role)) {
      popupStore.setMinorPopup(T.ContentPageMinorPopupType.SHARE);
    } else {
      dispatch(
        OpenContentPagePopup({
          popup: T.ContentPagePopupType.NO_PERMISSION,
        })
      );
    }
  }, [role]);

  const handleShareClick: MouseEventHandler<HTMLDivElement> = async e => {
    e.stopPropagation();
    setShowMenu(false);
    if (isInPointCloud) {
      return;
    }

    await Promise.all([
      dispatch(SetUpdateTwoDDisplayCenter({ shouldUpdateTwoDDisplayCenter: true })),
      dispatch(SetUpdateTwoDDisplayZoom({ shouldUpdateTwoDDisplayZoom: true })),
    ]);
    dispatch(
      OpenContentPagePopup({
        popup: isAllowSharePopup(role)
          ? T.ContentPagePopupType.EXTERNAL_SHARE
          : T.ContentPagePopupType.NO_PERMISSION,
      })
    );
  };

  const handleGuideClick: MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();
    setShowMenu(false);
    window.open(l10n(route.externalLink.support), '_blank');
  };

  return (
    <LogoContainer
      data-test-id="content-page-logo-tab"
      className={CANCELLABLE_CLASS_NAME}
      onClick={handleLogoClick}
      isActive={showMenu}
      ref={dropdownRef}
      customStyles={customStyles}
    >
      {logo}
      {showMenu ? (
        <DropdownMenu>
          <DropdownItem
            onClick={() => {
              navigate(route.project.main);
            }}
          >
            {l10n(Text.backToWorkspace)}
          </DropdownItem>
          <DropdownDivider />
          <DropdownItem onClick={handleDownloadDataClick}>{l10n(Text.downloadData)}</DropdownItem>
          <DropdownItem onClick={handleDataHistoryClick}>{l10n(Text.dataHistory)}</DropdownItem>
          <DropdownItem onClick={handlePrintClick}>{l10n(Text.print)}</DropdownItem>
          <DropdownItem onClick={handleMeasurementReportClick}>
            {l10n(Text.measurementReport)}
          </DropdownItem>
          <DropdownDivider />
          <DropdownItem onClick={handleInviteclick}>{l10n(Text.invite)}</DropdownItem>
          <DropdownItem onClick={handleShareClick}>{l10n(Text.share)}</DropdownItem>
          <DropdownDivider />
          <DropdownItem onClick={handleGuideClick}>{l10n(Text.goToUserGuide)}</DropdownItem>
        </DropdownMenu>
      ) : null}
    </LogoContainer>
  );
}
